#select-sort {
	appearance: none;
	background-image: url('/assets/img/arrow-down.svg');
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 0.6em;
}

input[type='radio'],
input[type='checkbox'] {
	border: 1px solid #6b7280;
}

input[type='checkbox'] {
	border-radius: 0.125rem;
}

input[type='checkbox']:focus {
	background-color: transparent;
	box-shadow: none;
}

input[type='radio']:checked,
input[type='radio']:focus,
input[type='checkbox']:checked,
input[type='checkbox']:checked:focus {
	accent-color: #1f3a7d !important;
	background-color: #1f3a7d;
	box-shadow: none;
}
