/* 17. Rankings */

@media only screen and (max-width: 900px) {
	.ranking-table-drop-1 {
		display: none !important;
	}
}

@media only screen and (max-width: 810px) {
	.ranking-table-drop-2 {
		display: none !important;
	}
}

@media only screen and (max-width: 730px) {
	.ranking-table-drop-3 {
		display: none !important;
	}
}

@media only screen and (max-width: 590px) {
	.ranking-table-drop-4 {
		display: none !important;
	}
}

@media only screen and (max-width: 420px) {
	.ranking-table-drop-5 {
		display: none !important;
	}
}

@media (min-width: 420px) {
	.rankings-col-2 {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}
