// tailwind directives
@layer utilities {
	.subnav-heading {
		@apply text-sm text-gray-500 font-semibold leading-5 mb-6;
	}

	.navlink-black {
		@apply text-base text-gray-900 font-semibold leading-6 no-underline hover:text-gray-700;
	}

	.navlink-blue {
		@apply flex items-center gap-3 text-sm text-uc-blue-900 font-semibold hover:text-uc-blue-950;
	}

	.subnav-gray {
		@apply text-base text-gray-500 font-semibold leading-5 hover:text-gray-800;
	}

	.subnav-link-padding {
		@apply gap-y-8 pb-8 lg:pb-0;
	}

	.nav-image {
		@apply rounded-md object-cover;
	}

	.nav-description {
		@apply text-sm text-gray-500 font-normal leading-5;
	}

	.side-style {
		@apply -mx-5 px-5 lg:mx-0 lg:px-0;
	}
}

.no-scoll {
	position: fixed;
	width: 100vw;

	@media screen and (min-width: 1024px) {
		position: static;
		width: static;
	}
}

.submenu {
	&.out-of-sight {
		position: absolute;
		width: 0;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border-width: 0;
	}

	@media screen and (min-width: 1024px) {
		position: absolute;
		right: 0;
		width: 100%;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
		white-space: normal;
		background: white;
		transform: translateY(0);
		transition: transform 0.3s;
		z-index: 101;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

		&.out-of-sight {
			transform: translateY(-130%);
			transition: transform 0.3s;
		}
	}
}

#mobile-nav {
	position: absolute;
	right: 0;
	width: 100%;
	background: white;
	transform: translateX(0);
	transition: transform 0.3s;
	z-index: 101;

	&.invisible {
		transform: translateX(130%);
		transition: transform 0.3s;
	}
}

.overlay-navbar-panel {
	.overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 100;
		background: rgba(#000, 0.3);
	}
}

@media screen and (max-width: 1024px) {
	.overlay-navbar-panel {
		.overlay {
			z-index: 104;
		}
	}
}

.h-nav {
	height: calc(100vh - 4.5rem);
}
