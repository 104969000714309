@use './variables';
/* 2. Background images & gradients */
#bg1 {
	background: #4fa2c2;
	background: linear-gradient(-90deg, #4fa2c2 0%, #ecedf8 100%);
}

@media screen and (min-width: 768px) {
	#bg1,
	.bg-space-desktop {
		background: #f9c5d1;
		background-image:
			url(variables.$cdn + '/assets/img/heroimg1.png'),
			linear-gradient(#f9c5d1, #9795ef 74%);
		background-position: 50%;
		background-size: cover;
	}
}

@media screen and (max-width: 768px) {
	.bg-space-desktop {
		background: #f9c5d1;
		background-image:
			url(variables.$cdn + '/assets/img/heroimg1_mobile.svg'),
			linear-gradient(#f9c5d1 0%, #9795ef 74%);
		background-size: cover;
		background-position: center 45%;
	}
}

#bg-adv {
	background: #8a2387;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #f27121, #e94057, #8a2387);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #f27121, #e94057, #8a2387);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

#bg-adv2 {
	background: #4cb8c4;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #3cd3ad, #4cb8c4);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #3cd3ad, #4cb8c4);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.bg-cta-contact {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: white;
	background-image: url(variables.$cdn + '/assets/img/contact_client_success_cta.png');
}

.bg-contact-james,
.bg-contact-danica {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: white;
}

.bg-contact-james {
	background-image: url(variables.$cdn + '/assets/img/portrait_james.jpg');
}

.bg-contact-danica {
	background-image: url(variables.$cdn + '/assets/img/portrait_danica.jpg');
}

.bg-cta-prospectus,
.bg-cta-ucas,
.bg-cta-request-info,
.bg-cta-request,
.bg-library,
.bg-cta-quiz,
.bg-cta-call-uni,
.bg-cta-register-interest,
.bg-cta-top-100,
.bg-cta-search-subject,
.bg-cta-subject-rankings,
.bg-course-search-directory-cta {
	background-size: cover;
	background-position: center;
}

.bg-cta-prospectus {
	background-image: url(variables.$cdn + '/assets/img/Order_Prospectus_Search_Image_CTA_Web_02.png');
}

.bg-cta-ucas {
	background-image: url(variables.$cdn + '/assets/img/Apply_T0_Uni_CTA.png');
}

.bg-cta-request-info {
	background-image: url(variables.$cdn + '/assets/img/Request_Info_Search_Image_CTA_Web_02.png');
}

.bg-cta-request {
	background-image: url(variables.$cdn + '/assets/img/Request-Info.png');
}

.bg-library {
	background-image: url(variables.$cdn + '/assets/img/Browse_Degree_Guides.png');
}

.bg-cta-quiz {
	background-image: url(variables.$cdn + '/assets/img/Degree_Quiz_Image_CTA_Web_01.png');
}

.bg-cta-call-uni {
	background-image: url(variables.$cdn + '/assets/img/call_uni_cta.png');
}

.bg-cta-register-interest {
	background-image: url(variables.$cdn + '/assets/img/bg_register_interest_cta.svg');
}

.bg-cta-top-100 {
	background-image: url(variables.$cdn + '/assets/img/Rankings_Top_100_CTA.png');
}

.bg-cta-search-subject {
	background-image: url(variables.$cdn + '/assets/img/Course_Search_Image_CTA_Web_01.png');
}

.bg-cta-subject-rankings {
	background-image: url(variables.$cdn + '/assets/img/Subject_Guide_Image_CTA.png');
}

.bg-course-search-directory-cta {
	background-image: url(variables.$cdn + '/assets/img/course_search_directory_cta.png');
}

#bg2 {
	background-color: #5de6de;
	background-image: linear-gradient(315deg, #5de6de 0%, #b58ecc 74%);
}

#bg-statements,
#bg-prospectus {
	background-color: #ffb714;
}

@media screen and (min-width: 1124px) {
	#bg-prospectus {
		background-image: url(variables.$cdn + '/assets/img/uc6.png');
		background-size: auto 100%;
		background-repeat: no-repeat;
		background-position: 90%;
	}
}

@media screen and (min-width: 1260px) {
	#bg-statements {
		background-image: url(variables.$cdn + '/assets/img/uc3.svg');
		background-size: auto 85%;
		background-repeat: no-repeat;
		background-position: 90%;
	}
}

#bg-grey {
	background-color: #f3f4f6;
}

#rankings-cards-bg {
	background: linear-gradient(360deg, #f9c5d1 0%, #9795ef 74%);
	/* fallback for old browsers */
	background: -webkit-linear-gradient(360deg, #f9c5d1 0%, #9795ef 74%);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(360deg, #f9c5d1 0%, #9795ef 74%);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#bg-header,
#courses-hero,
#bg-guides,
#bg-degree-guides,
#bg-city-guides,
#bg-career-guides,
#bg-advice,
#bg-advice-a-levels,
#bg-advice-accommodation,
#bg-advice-apprenticeships,
#bg-advice-gcses,
#bg-advice-student,
#bg-advice-personal-statement,
#bg-advice-postgraduate,
#bg-advice-student-finance,
#bg-advice-student-health,
#bg-advice-studying-abroad,
#bg-advice-teacher,
#bg-advice-ucas,
#bg-advice-work-experience,
#bg-advice-gap-year,
#bg-about,
#bg-statements,
#bg-rankings-all,
#bg-rankings-accommodation,
#bg-rankings-career-opportunities,
#bg-rankings-course-quality,
#bg-rankings-england,
#bg-rankings-northern-ireland,
#bg-rankings-scotland,
#bg-rankings-student-life,
#bg-rankings-wales,
#bg-event-search,
#bg-course-search,
#bg-event-landing,
#bg-uni-search,
#bg-home,
#bg-contact,
#bg-home-clearing,
#bg-quiz,
#bg-subject-guides {
	background: #1f3a7d;
}

#bg-course-search-clearing,
#bg-uni-search-clearing,
#clearing-hero {
	background: #0f766e;
}

@media screen and (min-width: 64em) {
	#courses-hero,
	#clearing-hero,
	#bg-guides,
	#bg-degree-guides,
	#bg-city-guides,
	#bg-career-guides,
	#bg-advice,
	#bg-advice-a-levels,
	#bg-advice-accommodation,
	#bg-advice-apprenticeships,
	#bg-advice-gcses,
	#bg-advice-student,
	#bg-advice-personal-statement,
	#bg-advice-postgraduate,
	#bg-advice-student-finance,
	#bg-advice-student-health,
	#bg-advice-studying-abroad,
	#bg-advice-teacher,
	#bg-advice-ucas,
	#bg-advice-work-experience,
	#bg-advice-gap-year,
	#bg-about,
	#bg-statements,
	#bg-rankings-all,
	#bg-rankings-accommodation,
	#bg-rankings-career-opportunities,
	#bg-rankings-course-quality,
	#bg-rankings-england,
	#bg-rankings-northern-ireland,
	#bg-rankings-scotland,
	#bg-rankings-student-life,
	#bg-rankings-wales,
	#bg-event-search,
	#bg-course-search,
	#bg-course-search-clearing,
	#bg-event-landing,
	#bg-uni-search,
	#bg-uni-search-clearing,
	#bg-home,
	#bg-home-clearing,
	#bg-contact,
	#bg-quiz,
	#bg-subject-guides {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	#bg-statements {
		background-image: url(variables.$cdn + '/assets/img/Personal_Statement_Page_Banner_02 (1).png');
	}
	#bg-about {
		background-image: url(variables.$cdn + '/assets/img/About Us_Banner_Web.png');
	}
	#bg-advice-gap-year {
		background-image: url(variables.$cdn + '/assets/img/gap_year.svg');
	}
	#clearing-hero {
		background-image: url(variables.$cdn + '/assets/img/clearing_landing_banner.png');
	}
	#bg-guides {
		background-image: url(variables.$cdn + '/assets/img/Advice_Page_Banner_01.png');
	}
	#bg-header {
		background: #dad1d1;
	}
	#bg-degree-guides {
		background-image: url(variables.$cdn + '/assets/img/Advice_Page_Banner_01.png');
	}
	#bg-subject-guides {
		background-image: url(variables.$cdn + '/assets/img/subjects_guide_banner.png');
	}
	#bg-city-guides {
		background-image: url(variables.$cdn + '/assets/img/Advice_Page_Banner_01.png');
	}
	#bg-career-guides {
		background-image: url(variables.$cdn + '/assets/img/Advice_Page_Banner_01.png');
	}
	#bg-advice {
		background-image: url(variables.$cdn + '/assets/img/Advice_Page_Banner_01.png');
	}
	#bg-advice-a-levels {
		background-image: url(variables.$cdn + '/assets/img/A_Levels.svg');
	}
	#bg-advice-accommodation {
		background-image: url(variables.$cdn + '/assets/img/Accomodation.svg');
	}
	#bg-advice-apprenticeships {
		background-image: url(variables.$cdn + '/assets/img/Apprenticeships.svg');
	}
	#bg-advice-gcses {
		background-image: url(variables.$cdn + '/assets/img/GCSE.svg');
	}
	#bg-advice-student {
		background-image: url(variables.$cdn + '/assets/img/student_advice.svg');
	}
	#bg-advice-personal-statement {
		background-image: url(variables.$cdn + '/assets/img/personal_statement.svg');
	}
	#bg-advice-postgraduate {
		background-image: url(variables.$cdn + '/assets/img/post_graduate.svg');
	}
	#bg-advice-student-finance {
		background-image: url(variables.$cdn + '/assets/img/student_finance.svg');
	}
	#bg-advice-student-health {
		background-image: url(variables.$cdn + '/assets/img/student_health.svg');
	}
	#bg-advice-studying-abroad {
		background-image: url(variables.$cdn + '/assets/img/studying_abroad.svg');
	}
	#bg-advice-teacher {
		background-image: url(variables.$cdn + '/assets/img/teacher_advice.svg');
	}
	#bg-advice-ucas {
		background-image: url(variables.$cdn + '/assets/img/ucas_advice.svg');
	}
	#bg-advice-work-experience {
		background-image: url(variables.$cdn + '/assets/img/work_advice.svg');
	}
	#bg-rankings-all {
		background-image: url(variables.$cdn + '/assets/img/Rankings_Page_Banner_01.png');
	}
	#bg-rankings-accommodation {
		background-image: url(variables.$cdn + '/assets/img/Accomodation_Ranking_Page_Banner_01.png');
	}
	#bg-rankings-career-opportunities {
		background-image: url(variables.$cdn + '/assets/img/Graduate_Career_Rankings_Page_Banner_02.png');
	}
	#bg-rankings-course-quality {
		background-image: url(variables.$cdn + '/assets/img/Course_Quality_Rankings_Page_Banner_01.png');
	}
	#bg-rankings-england {
		background-image: url(variables.$cdn + '/assets/img/England_Rankings_Page_Banner_01.png');
	}
	#bg-rankings-northern-ireland {
		background-image: url(variables.$cdn + '/assets/img/Northern_Ireland_Rankings_Page_Banner.png');
	}
	#bg-rankings-scotland {
		background-image: url(variables.$cdn + '/assets/img/Scotland_Rankings_Page_Banner.png');
	}
	#bg-rankings-student-life {
		background-image: url(variables.$cdn + '/assets/img/Student_Life_Rankings_Page_Banner_01.png');
	}
	#bg-rankings-wales {
		background-image: url(variables.$cdn + '/assets/img/Wales_Rankings_Page_Banner.png');
	}
	#bg-event-search {
		background-image: url(variables.$cdn + '/assets/img/Open_Days_Page_Banner_01.png');
		background-position: top center;
	}
	#bg-course-search {
		background-image: url(variables.$cdn + '/assets/img/Course_Search_Page_Banner_01.png');
		background-position: 90% 50%;
	}
	#bg-course-search-clearing {
		background-image: url(variables.$cdn + '/assets/img/clearing_courses_banner.png');
		background-position: 90% 50%;
	}
	#bg-event-landing {
		background-image: url(variables.$cdn + '/assets/img/Open_Days_Page_Banner_01.png');
	}

	#bg-home {
		background-image: url(variables.$cdn + '/assets/img/Homepage Hero Banner.png');
	}

	#bg-home-clearing {
		background-image: url(variables.$cdn + '/assets/img/home_clearing_landing_banner.png');
	}

	#bg-uni-search {
		background-image: url(variables.$cdn + '/assets/img/University_Search_Page_Banner_01.png');
	}

	#bg-uni-search-clearing {
		background-image: url(variables.$cdn + '/assets/img/clearing_universities_search_banner.png');
	}

	#bg-contact {
		background-image: url(variables.$cdn + '/assets/img/About Us_Banner_Web.png');
	}

	#bg-quiz {
		background-image: url(variables.$cdn + '/assets/img/Degree_Quiz_Page_Banner_01.png');
	}
}

.bg-location {
	background-image: url(variables.$cdn + '/assets/img/Open_Days_Image_CTA_Web_01.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

#bg-sign {
	background-color: #5fd2d7;
	background-image: url(variables.$cdn + '/assets/img/login.svg');
	background-position: center bottom;
	background-size: 1200px;
	background-repeat: repeat;
	position: relative;
}

#bg-sign-overlay {
	background-color: #5fd2d7;
	background-image: url(variables.$cdn + '/assets/img/Sign_In_Side_Banner_Web.png');
	background-position: center bottom;
	background-size: cover;
	background-repeat: repeat;
	position: relative;
}

.bg-register {
	background-image: url(variables.$cdn + '/assets/img/register.png');
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;
}
