// ***Dashboard***
@media screen and (max-width: 767px) {
	.chart-container {
		height: 300px;
	}
}

@media screen and (min-width: 768px) {
	.chart-container {
		height: 400px;
	}
}

.cursor-cross {
	cursor: crosshair;
}

/* University Header */
.uni-mobile-menu {
	transform: translateY(-100%);
	z-index: 10;
	transition: 300ms ease;
}

.show-uni-mobile-menu {
	transform: translateY(0);
	z-index: 10;
	transition: 300ms ease;
}
// ***Dashboard***
