@use 'swiper/css';
@use 'swiper/css/navigation';
@use 'swiper/css/pagination';

@use 'ngx-toastr/toastr';

@use './styles/variables';
@use './styles/article';
@use './styles/background-images';
@use './styles/calendar';
@use './styles/dashboard';
@use './styles/events-uni-course-page';
@use './styles/loader';
@use './styles/rankings';
@use './styles/swiper-styles';
@use './styles/toast';
@use './styles/tailwind-directives';
@use './styles/tooltip';
@use './styles/snigel';
@use './styles/navigation' as nav;
@use './styles/forms';
@use './styles/recaptcha';
@use './styles/faqs';
@use './styles/tables';

@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown-content {
	visibility: hidden;
	transform: scale(0.9);
	opacity: 0;
	z-index: -10;
	transition: all 100ms ease;
}

.open > .dropdown-content {
	visibility: visible;
	opacity: 1 !important;
	transform: scale(1) !important;
	transition: all 0.1s ease !important;
	z-index: 100 !important;
}

.home-dropdown-content {
	left: -999px;
	transform: scale(0.9);
	opacity: 0;
	z-index: -10;
	transition:
		transform 0.1s ease,
		opacity 0.1s ease;
}

.open > .home-dropdown-content {
	left: 0;
	opacity: 1 !important;
	transform: scale(1) !important;
	transition:
		transform 0.1s ease,
		opacity 0.1s ease;
	z-index: 100 !important;
}

@media only screen and (max-width: 1023px) {
	.mobile-filter-overlay {
		position: absolute;
		z-index: 103;
		top: 0;
		right: 0;
		transform: translateX(100%) !important;
	}

	.mobile-filter-overlay-show {
		position: absolute;
		z-index: 103;
		top: 0;
		right: 0;
		transition: all 0.2s ease-in-out;
	}

	.course-search-filter {
		position: fixed;
		z-index: 103;
		top: 0;
		right: 0;
		transform: translateX(100%);
		transition: 0.2s ease-in-out;

		&.open {
			transform: none;
		}
	}
}

/* 14. Filter Overlay */
.filter-overlay-search:focus,
.filter-overlay-search:hover {
	box-shadow: 0px 0px 4px #0073ff;
}

/* this class ensures that outline only gets applied on keyboard tab but not on click */
.only-tab-focus:focus:not(:focus-visible) {
	outline: none;
}

.show {
	display: block !important;
}

/* disabled color*/
.disabled {
	background-color: #ddd !important;
}

.pl-not-first:not(:first-child) {
	padding-left: 0.5rem;
}

.gallery-btn-placement {
	top: 50%;
	transform: translateY(-50%);
}

.reg-tool-w {
	width: 516px;
}

.img-grayscale {
	filter: grayscale(100%);
}

/* 3. Spookiest universities */

.bgtsu:nth-child(even) {
	background: rgba(255, 247, 237, 1);
}

/* 4. Dashboard/University */
.marked {
	margin-left: 26px;
	margin-top: -21px;
}

#helpContainer {
	top: 55px;
	z-index: 100;
}

#notification {
	top: 54px;
	z-index: 100;
}

#uniProfile {
	top: 36px;
	z-index: 100;
}

/* 8. Dropdown Select */
.dropdown {
	width: 100%;
	border: 1px solid rgba(209, 213, 219);
	border-radius: 0.375rem;
	box-shadow: 0 2px 5px -3px rgba(0, 0, 0, 0.1);
	text-indent: 0.5rem;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Mega Menu Additional Styles Start */
.menu__tab-title:focus {
	border-bottom: 4px solid #1f3a7d !important;
	color: #1f3a7d !important;
}

.menu__tab-title:focus > .menu__tab-svg {
	color: #1f3a7d !important;
}

.menu__image-text {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	text-align: center;
	font-size: 20px;
}

.menu__img-size {
	height: 150px;
	width: 100%;
}

.menu__image-animation {
	transform: scale(1);
	background-position: center;
	transition: all 250ms ease-in-out;
}

.menu__image-animation:hover {
	transform: scale(1.015);
	background-position: center;
	transition: all 250ms ease-in-out;
	cursor: pointer;
}

.menu__img-properties {
	transition: all 250ms ease-in-out;
	filter: brightness(75%);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.menu__img-courses {
	background-image: url(variables.$cdn + '/assets/img/courses.jpg');
}

.menu__img-open-day {
	background-image: url(variables.$cdn + '/assets/img/openDays.jpg');
}

.menu__img-unis {
	background-image: url(variables.$cdn + '/assets/img/universities.jpg');
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

/* 11. Profile postgraduate / university-profile / profile-reviews */
.sticky-lead-bar {
	transform: translateY(100%);
	transition: transform 0.3s ease;
	position: fixed;
	bottom: 0;
	left: 0;
}

.show-sticky-lead-bar {
	transform: translateY(0%);
	z-index: 10000;
	transition: transform 0.3s ease;
	position: fixed;
	bottom: 0;
	left: 0;
}

.highlight {
	background-color: #ffff00;
}

/* 12. Prospectus */
.tag.prospectus {
	width: 49px;
	height: 49px;
	font-size: 1.5em;
	padding: 7px 0px;
	font-weight: bolder;
}

/* Tooltip user profile */
.tooltip-leads {
	cursor: pointer;
	position: relative;
	width: max-content;
}

.tooltip-leads::before,
.tooltip-leads::after {
	color: rgb(107, 114, 128);
	background-color: #fff;
	font-size: 14px;
	opacity: 0;
	pointer-events: none;
	text-align: center;
}

.tooltip-leads::before {
	content: attr(aria-label);
	position: absolute;
	bottom: 170%;
	left: 0;
	min-width: 200px;
	background-color: white;
	border-radius: 5px;
	font-weight: 400;
	padding: 0.7rem;
	transform: translateX(-50%);
	transition: opacity 0.5s ease;
	box-shadow:
		0px 12px 16px -4px rgb(16 24 40 / 8%),
		0px 4px 6px -2px rgb(16 24 40 / 3%);
}

.tooltip-leads::after {
	content: '';
	position: absolute;
	bottom: 130%;
	right: 50%;
	width: 1rem;
	height: 1rem;

	transform: translateX(50%) rotate(45deg);
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid white;
	transition: opacity 0.5s ease;
}

.tooltip-leads:hover::before,
.tooltip-leads:hover::after {
	opacity: 1;
	transition: opacity 0.5s ease;
}

.tooltip {
	display: none;
}

.tooltip-hover:hover .tooltip {
	display: block;
}

@media only screen and (min-width: 400px) {
	.tooltip-leads::before {
		min-width: 250px;
	}
}

@media only screen and (min-width: 768px) {
	.tooltip-leads::before {
		min-width: 420px;
	}
}

/* 18. Notifications modal */
.modal {
	transform: translateX(50px);
	opacity: 0;
	z-index: -1;
}

.modal-show {
	z-index: 200;
	transform: translateX(0);
	opacity: 1;
	transition: all 300ms ease;
}

/* END NOTIFICATIONS MODAL */

.gallery-btn-placement {
	top: 50%;
	transform: translateY(-50%);
}

.error {
	height: 0px;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
}

.error p {
	cursor: default;
}

.error-show {
	z-index: 1;
	height: 38px;
	visibility: visible;
	transition: all 0.3s ease-in-out;
}

.container-max-height {
	max-height: 370px;
	overflow: scroll;
}

.-m-top-1 {
	margin-top: -1px;
}

.show-label {
	transform: translateY(-60%);
	visibility: visible;
	transition: all 0.2s ease-in-out;
}

.label {
	transform: translateY(-30%);
	visibility: hidden;
	transition: all 0.1s ease-in-out;
}

.menu__tab-title:focus {
	border-bottom: 4px solid #1f3a7d !important;
	color: #1f3a7d !important;
}

.menu__tab-title:focus > .menu__tab-svg {
	color: #1f3a7d !important;
}

.menu__image-text {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	text-align: center;
	font-size: 20px;
}

.menu__img-size {
	height: 150px;
	width: 100%;
}

.menu__link-text:hover {
	color: #1f3a7d;
}

.menu__image-animation {
	transform: scale(1);
	background-position: center;
	transition: all 250ms ease-in-out;
}

.menu__image-animation:hover {
	transform: scale(1.015);
	background-position: center;
	transition: all 250ms ease-in-out;
	cursor: pointer;
}

.menu__img-properties {
	transition: all 250ms ease-in-out;
	filter: brightness(75%);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.menu__img-courses {
	background-image: url(variables.$cdn + '/assets/img/courses.jpg');
}

.menu__img-open-day {
	background-image: url(variables.$cdn + '/assets/img/openDays.jpg');
}

.menu__img-unis {
	background-image: url(variables.$cdn + '/assets/img/universities.jpg');
}

.shadow-b-prospectus {
	box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}

.bookmark span {
	color: #1f3a7d;
}

.bookmark:hover span {
	color: #fff;
}
.bookmark:hover svg {
	color: #fff;
}

@media screen and (min-width: 640px) and (max-width: 764px) {
	.keyword-logo {
		display: none;
	}
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
	.keyword-logo {
		display: none;
	}
}

.overflow-no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.overflow-no-scrollbar::-webkit-scrollbar {
	display: none;
}
