/* 21. Calendar */
:root {
	--selected-date: #1f3a7d;
	--hover-date: #e5e7eb;
}

.calendar-day {
	border-radius: 20px;
	padding: 8px;
	display: block;
	border: 1px solid #fcfcfc;
	box-sizing: border-box;
}

.calendar-day[aria-disabled='false']:not(.selected-date):hover {
	background-color: var(--hover-date);
	border: 1px solid var(--hover-date);
}

.calendar-day[data-month='previous'],
.calendar-day[data-month='next'] {
	color: #747474;
}

.calendar-day:focus {
	outline: 2px solid var(--selected-date);
	outline-offset: -2px;
}

.today {
	border: 1px solid var(--selected-date);
}

.selected-date {
	background-color: var(--selected-date);
	border: 1px solid var(--selected-date);
	border-radius: 0;
	color: #fff;
	font-weight: 600;
}

.selected-date[data-month='previous'],
.selected-date[data-month='next'] {
	background-color: var(--selected-date);
	border: 1px solid var(--selected-date);
	border-radius: 0;
	color: var(--selected-date);
}

.selected-date[data-month='previous']:hover,
.selected-date[data-month='next']:hover {
	background-color: var(--selected-date);
	border: 1px solid var(--selected-date);
	border-radius: 0;
	color: var(--selected-date);
}

.selected-date[data-month='current']:hover {
	background-color: var(--hover-date);
	border-color: var(--hover-date);
	color: #000;
}

.radius-left {
	border-radius: 20px 0px 0px 20px;
}

.radius-right {
	border-radius: 0px 20px 20px 0px;
}

.radius-none {
	border-radius: 0px;
}

.radius-full {
	border-radius: 20px;
}

.date-blue:hover {
	background-color: var(--selected-date);
	color: var(--selected-date);
	border-color: var(--selected-date);
}

.calendar-day[aria-disabled='true'] {
	cursor: default;
	color: #747474;
	user-select: none;
}
