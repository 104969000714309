iframe {
	display: inline-block;
}

.incontent_1_mob-0 {
	max-width: 300px;
	height: 250px;
}

.incontent_1_dskt-0 {
	max-width: 970px;
	height: 250px;
}

.incontent_4_dskt-0 {
	max-width: 300px;
	height: 600px;
}

.adngin {
	padding: 2rem 0;
	margin: 2rem 0;

	border-top: 1px solid #e5e7eb;
	border-bottom: 1px solid #e5e7eb;
}

.incontent_3_mob-0 {
	max-width: 300px;
	min-height: 250px;
	margin: auto;
}

.incontent_3_dskt-0 {
	display: none;
	max-width: 750px;
	min-height: 250px;
	margin: auto;
}

@media screen and (min-width: 640px) {
	.adngin {
		padding: 4rem 0;
		margin: 4rem 0;
	}

	.incontent_3_mob-0 {
		display: none;
	}

	.incontent_3_dskt-0 {
		display: block;
	}
}

/* snigel adhesive */
#adngin-bottom_adhesive_dskt-0 {
	max-width: 970px;
	height: 90px;
	margin: auto;
}

#adngin-bottom_adhesive_mob-0 {
	max-width: 320px;
	height: 50px;
	margin: auto;
}

/* snigel cookie banner - only allowed to change border radii */
.sn-inner {
	border-radius: 0px !important;
}

#sn-b-custom,
#sn-b-save {
	border-radius: 6px !important;
}

#accept-choices {
	border-radius: 6px;
}

.sn-collapsible {
	box-shadow: none !important;
}

@media screen and (min-width: 768px) {
	.sn-inner {
		border-radius: 8px !important;
	}
}
