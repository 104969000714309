/* 22. Events, University & Course Page */
.events-bg {
	background: #fcfcfc;
}

.search-bar-shadow {
	box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.08);
	margin-bottom: 4px;
}

.text-green {
	color: rgba(15, 118, 110, 1);
}

@media only screen and (min-width: 640px) {
	.event-ticket::before,
	.event-ticket::after {
		pointer-events: none;
		position: absolute;
		content: '';
		width: 18px;
		height: 13px;
		border-left: 1px solid rgba(229, 231, 235, 1);
		border-right: 1px solid rgba(229, 231, 235, 1);
	}

	.event-ticket::before {
		top: -2px;
		left: -10px;
	}
	.event-ticket::after {
		bottom: -2px;
		left: -10px;
	}

	.event-ticket-white::before {
		background-color: white;
		border-radius: 0 0 18px 18px;
		border-bottom: 1px solid rgba(229, 231, 235, 1);
	}

	.event-ticket-white::after {
		background-color: white;
		border-radius: 18px 18px 0 0;
		border-top: 1px solid rgba(229, 231, 235, 1);
	}

	.event-ticket-blue::before {
		top: -4px;
		left: -10px;
		background-color: #1f3a7d;
		border-radius: 0 0 18px 18px;
		border: transparent;
	}

	.event-ticket-blue::after {
		bottom: -4px;
		left: -10px;
		background-color: #1f3a7d;
		border-radius: 18px 18px 0 0;
		border: transparent;
	}
}
