.swiper {
	width: 100%;
	height: 100%;
}
.swiper-slide {
	background: #f1f1f1;
	color: #000;
	text-align: center;
	width: 100%;
	height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
	position: static;
	inset: auto;
	margin-top: auto;
	height: auto;
}

.swiper-button-next::after,
.swiper-button-prev::after {
	font-size: 1rem;
	color: #000;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	opacity: 0.5;
}

.swiper-slide {
	height: auto;
	align-self: stretch;
	border-radius: 0.5rem;
	background-color: inherit;
}
