/* 16. Tooltips */

.tooltip-toggle {
	position: relative;
}

.tooltip-toggle::before,
.tooltip-toggle::after {
	color: #efefef;
	font-size: 12px;
	opacity: 0;
	pointer-events: none;
	text-align: center;
}

.tooltip-toggle::before {
	position: absolute;
	bottom: 130%;
	right: 50%;
	transform: translateX(50%);
	background-color: #2b222a;
	border-radius: 5px;
	color: #fff;
	content: attr(aria-label);
	padding: 0.7rem;
	text-transform: none;
	transition: all 0.5s ease;
	min-width: 160px;
	font-weight: lighter;
}

.tooltip-toggle::after {
	position: absolute;
	bottom: 110%;
	right: 50%;
	transform: translateX(50%);
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid #2b222a;
	content: ' ';
	font-size: 0;
	line-height: 0;
	width: 0;
	transition: all 0.5s ease;
}

.tooltip-toggle:hover::before,
.tooltip-toggle:hover::after {
	opacity: 1;
	transition: all 0.5s ease;
}

@media only screen and (min-width: 640px) and (max-width: 1024px) {
	.tooltip-toggle-2::before {
		right: 0;
		transform: translateX(30%);
	}
}

@media only screen and (max-width: 639px) {
	.tooltip-toggle-2::before {
		right: 0;
		transform: translateX(10%);
	}
}

/* Tooltip Leads */
.tooltip-leads {
	cursor: pointer;
	position: relative;
	width: max-content;
}

.tooltip-leads::before,
.tooltip-leads::after {
	color: rgb(107, 114, 128);
	font-size: 14px;
	opacity: 0;
	pointer-events: none;
	text-align: center;
}

.tooltip-leads::before {
	position: absolute;
	bottom: 160%;
	right: 50%;
	transform: translateX(15%);
	background-color: white;
	border-radius: 5px;
	font-weight: 400;
	content: attr(aria-label);
	padding: 0.7rem;
	transition: all 0.5s ease;
	min-width: 420px;
}

.tooltip-leads::after {
	position: absolute;
	bottom: 130%;
	right: 50%;
	transform: translateX(50%);
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid white;
	content: ' ';
	font-size: 0;
	line-height: 0;
	width: 0;
	transition: all 0.5s ease;
}

.tooltip-leads:hover::before,
.tooltip-leads:hover::after {
	opacity: 1;
	transition: all 0.5s ease;
}

.tooltip {
	display: none;
}

.tooltip-hover:hover .tooltip {
	display: block;
}

.tooltip-directive {
	position: fixed;
}

.tooltip-content {
	position: absolute;
	top: -1rem;
	transform: translateX(-50%) translateY(-100%);
	background: #2b222a;
	color: #fff;
	text-align: center;
	border-radius: 5px;
	padding: 0.7rem;
	width: max-content;
	min-width: 160px;
	max-width: 250px;
	z-index: 1000;
}

.tooltip-header {
	font-size: 12px;
	text-align: left;
	overflow: hidden;
	margin-bottom: 4px;
}

.tooltip-body {
	font-size: 12px;
	text-align: left;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.tooltip-directive::after {
	content: '';
	position: absolute;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 8px solid #2b222a;
	transform: translateX(-50%) translateY(calc(-100% - 9px));
}
