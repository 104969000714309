.faqs {
	ul {
		list-style-type: disc;
		padding-left: 1.5em;
	}

	strong {
		font-weight: 600;
	}

	a:not(.reset-link-style) {
		color: #2b6ae5;
		text-decoration: underline;
	}

	a:not(.reset-link-style):hover {
		color: #1e40af;
	}
}
