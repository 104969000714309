/* 5. Guides */
.article h2 {
	margin: 3rem 0 2rem 0;
	color: #000;
	font-size: 1.25rem;
	line-height: 2.5rem;
	font-weight: 700;
}

.article h3 {
	--tw-text-opacity: 1;
	color: rgba(17, 24, 39, var(--tw-text-opacity));
	font-size: 1.15rem;
	line-height: 2.2rem;
	font-weight: 500;
}

.article p {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

.article img {
	width: 100%;
	border-radius: 8px;
}

.article li {
	list-style-type: disc;
	list-style-position: inside;
}

.article a {
	color: #2b6ae5;
	text-decoration: underline;
}

.article a:hover {
	color: #2346aa;
}

.article table {
	width: 100%;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
}

.article table::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #e5e7eb;
	border-radius: 8px;
	box-shadow:
		0px 1px 3px rgba(0, 0, 0, 0.1),
		0px 1px 2px rgba(0, 0, 0, 0.06);
	z-index: 10;
}

.article th,
.article tr:not(:last-child) {
	border-bottom: 1px solid #e5e7eb;
}

.article thead {
	background-color: #f9fafb;
}

.article th {
	color: #111827;
	padding: 12px 24px 10px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.05em;
	font-size: 12px;
	line-height: 16px;
	text-align: left;
	overflow: hidden;
}

.article td {
	color: #6b7280;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	padding: 16px 24px;
}

.article q {
	color: #111827;
	font-style: italic;
	font-weight: 500;
	font-size: 18px;
	line-height: 32px;
	padding-left: 22px;
	border-left: 4px solid #e5e7eb;
	display: block;
}
