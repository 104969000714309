/* 20. Loader */
.loader {
	border: 4px solid #a5a5a5;
	border-radius: 50%;
	border-top: 4px solid #e6e6e6;
	width: 30px;
	height: 30px;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* Loading */
@keyframes ldio-rpinwye8j0b {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.ldio-rpinwye8j0b div {
	position: absolute;
	animation: ldio-rpinwye8j0b 1s linear infinite;
	width: 160px;
	height: 160px;
	top: 20px;
	left: 20px;
	border-radius: 50%;
	box-shadow: 0 4px 0 0 #e15b64;
	transform-origin: 80px 82px;
}

@media only screen and (max-width: 640px) {
	.ldio-rpinwye8j0b div {
		width: 120px;
		height: 120px;
		top: 20px;
		left: 40px;
		transform-origin: 60px 62px;
	}
}

.loadingio-eclipse {
	width: 200px;
	height: 200px;
	display: inline-block;
	overflow: hidden;
}

@media only screen and (max-width: 640px) {
	.loadingio-eclipse {
		height: 150px;
	}
}

.ldio-rpinwye8j0b {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
	/* see note above */
}

.ldio-rpinwye8j0b div {
	box-sizing: content-box;
}

/* end loading */
