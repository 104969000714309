.toast-container {
	display: grid;
	gap: 5px;
	z-index: 9999;
	position: fixed;
	top: 20px;
	right: 20px;
	margin: 0 !important;
	padding: 0 !important;
}

.ngx-toastr {
	background-color: white !important;
	color: black !important;
	margin: 0 !important;
	width: 300px;
	padding: 0 !important;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.toast-progress {
	opacity: 1;
}
